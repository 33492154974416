import { library } from '@fortawesome/fontawesome-svg-core';
import { faClipboard } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@jetshop/ui/utils/theme';
import React, { useEffect, useState } from 'react';
import styled from 'react-emotion';

library.add(faClipboard);

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

const Input = styled('input')`
  height: 40px;
  background: #f7f7f7;
  border: 1px solid #dedede;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  padding-right: 40px;
  cursor: copy;
  ${({ disabled }) =>
    disabled &&
    css`
      background: #ffffff;
      color: #808080;
    `}

  &.ok {
    border: 1px solid ${theme('colors.green')} !important;
  }

  &.error {
    border: 1px solid ${theme('colors.red')} !important;
  }

  &:focus {
    outline: none;
    &.ok {
      border: 1px solid ${theme('colors.green')} !important;
    }

    &.error {
      border: 1px solid ${theme('colors.red')} !important;
    }
  }
`;

const Relative = styled('div')`
  position: relative;
  margin-bottom: 0.25rem;
  .pos {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 12px;
    font-size: 20px;
    transform: translateY(-50%);
  }
`;

const OkLabel = styled('span')`
  background: ${theme('colors.green')};
  text-transform: uppercase;
  font-family: 'Abel', sans-serif;
  font-size: 1.3rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  color: white;
  padding: 0.5rem 1rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SharedCartLink = ({ products }) => {
  const [state, setState] = useState('idle');
  const [shareUrl, setShareUrl] = useState('');

  useEffect(() => {
    try {
      const baseUrl = window.location.origin;
      const path = '/delad-varukorg/?';
      let fullUrl = baseUrl + path;
      for (const product of products) {
        fullUrl += `artNo=${product?.articleNumber}\$${product?.quantity}&`;
      }
      fullUrl = fullUrl.slice(0, -1);

      setShareUrl(fullUrl);
    } catch (e) {
      console.error('Error setting share cart url\n', e);
    }
  }, [products]);

  return (
    <Container>
      <span>Klicka på länken för att kopiera och dela din varukorg:</span>
      <Relative>
        {state === 'ok' ? <OkLabel>Kopierat!</OkLabel> : null}
        <Input
          type="text"
          name="shareUrl"
          readonly
          value={shareUrl}
          className={state === 'ok' ? 'ok' : state === 'error' ? 'error' : ''}
          onClick={() => {
            if (state !== 'idle') return;

            try {
              navigator.clipboard.writeText(shareUrl);
              setState('ok');
              setTimeout(() => {
                setState('idle');
              }, 2000);
            } catch (err) {
              console.error('Failed to copy:\n', err);
              setState('error');
              setTimeout(() => {
                setState('idle');
              }, 2000);
            }
          }}
        />
        <FontAwesomeIcon className="pos" icon={['fal', 'clipboard']} />
      </Relative>
    </Container>
  );
};
