import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { injectGlobal } from 'react-emotion';
import { SkoterdelenTheme } from './SkoterdelenTheme';

injectGlobal`
  html, body {
    font-family: 'Abel', sans-serif;
  }
  body {
    background: #f8f8f8;
  }
  /* apply a natural box layout model to all elements, but allowing components to change */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  strong, b {
    font-weight: bold;
  }
  .ReactModal__Overlay {
    z-index: 99;
  }
  svg {
    width:0;
  }

  a {
    text-decoration: none;
  }
  input {
    font-family: 'Abel', sans-serif;

  }

`;

export const theme = {
  ...SkoterdelenTheme,
};

export default (props) => <ThemeProvider {...{ ...props, theme }} />;
